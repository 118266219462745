<script setup lang="ts">
import { getWalletInfo } from '../hooks/loginUtils'

const $props = withDefaults(defineProps<{
  modelValue?: boolean
}>(), {
  modelValue: false,
})

const emits = defineEmits<{
  (e: 'update:modelValue', data: boolean): void
  (e: 'confirm'): void
}>()

const visibleRef = useModel($props, 'modelValue')

const walletInfo = getWalletInfo()

function onConfirm() {
  visibleRef.value = false
  emits('confirm')
}
</script>

<template>
  <ClientOnly>
    <AModal
      v-model:open="visibleRef"
      wrapClassName="ant-cover__basic-modal"
      :width="450"
      closable
      centered
      :maskClosable="false"
      :title="null"
      :footer="null"
    >
      <div class="w-full">
        <div class="flex justify-center mt-4">
          <img class="w-[90px]" :src="walletInfo.imgSrc" alt="Failed to load">
        </div>
        <div class="text-lg text-center my-5">{{ $t('4WjVvLBEln3t0VpLmD3') }}</div>
        <div class="text-center mb-7.5">{{ $t('c_7bxMavFrZcCyWqnLs25') }}</div>
      </div>
      <div class="w-full text-center">
        <AButton class="w-[70%] text-sm comfirm-btn" data-test-id="vczg" @click="onConfirm">
          {{ $t('1yBsgPptq2rtyWrLaO1Jq') }}
        </AButton>
      </div>
    </AModal>
  </ClientOnly>
</template>
